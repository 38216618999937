<template>
	<div class="p-grid" style="padding: 90px 1em 1em 1em;">
		<div class="p-col-12">
			<div class="card pag-padding">
                <div class="ttitle">{{$store.state.title[i18n.locale()]}}</div><br>
				<DataTable :value="products" v-model:expandedRows="expandedRows" class="p-datatable-customers" 
					dataKey="id" @row-expand="onRowExpand" @row-collapse="onRowCollapse">
					<div class="table-header">
							<span class="p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" :placeholder="$t('Search')+'...'" />
                            </span>
							<ProgressBar :value="total" style="height: 30px; width: 20rem"></ProgressBar>
							<div>
								<Button icon="pi pi-plus" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" v-tooltip.bottom="$t('New')" @click="New" v-if="insert"/>
								<Button icon="pi pi-refresh" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" v-tooltip.bottom="$t('Refresh')" @click="Start"/>
								<Button icon="pi pi-download" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" v-tooltip.bottom="$t('Download')" v-if="edit"/>
							</div>
					</div>
					<Column :expander="true" headerStyle="width: 5%" />
					<Column field="ord" :header="$t('Item')" headerStyle="width: 5%"/>
					<Column field="name" :header="$t('Controls')">
						<template #body="slotProps">
							{{slotProps.data[i18n.locale()]}} 
						</template>
					</Column>
					<Column field="criteria" :header="$t('Criteria')" headerStyle="width: 10%">
						<template #body="slotProps">
							{{slotProps.data.details.filter(val => val.activo).length}}
						</template>
					</Column>
					<Column field="score" :header="$t('Max. Score')" headerStyle="width: 10%">
						<template #body="slotProps">
							{{parseFloat(slotProps.data.details.filter(val => val.activo).length*slotProps.data.weight).toFixed(2)}}
						</template>
					</Column>
					<Column field="weight" :header="$t('Weight')" headerStyle="width: 10%">
						<template #body="slotProps">
							{{slotProps.data.weight}}%
						</template>
					</Column>
					<Column :header="$t('Status')" headerStyle="width: 10%">
						<template #body="slotProps">
							<span class="p-tag p-tag-info"  v-if="slotProps.data.activo">{{$t('Asset')}}</span>
							<span class="p-tag p-tag-info"  v-else>{{$t('Inactive')}}</span>
						</template>
					</Column>
					<Column :header="$t('Actions')" headerStyle="width: 15%">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-text p-button-secondary p-mr-2 p-mb-2" v-tooltip.bottom="$t('Edit')" @click="Edit(slotProps.data)" v-if="edit"/>
							<Button icon="pi pi-plus" class="p-button-text p-button-secondary p-mr-2 p-mb-2" v-tooltip.bottom="$t('New')" @click="New2(slotProps.data)" v-if="insert"/>
							<Button icon="pi pi-trash" class="p-button-text p-button-secondary p-mr-2 p-mb-2" v-tooltip.bottom="$t('Delete')" @click="confirmDeleteProduct(slotProps.data)" v-if="del && slotProps.data.details.length==0"/>
						</template>
					</Column>
					<template #expansion="slotProps">
						<div style="padding: 0rem 0rem 0rem 5rem">
							<DataTable :value="slotProps.data.details">
								<Column field="ord" :header="$t('Item')" headerStyle="width: 5%"/>
								<Column field="en" :header="$t('Assessment Criteria')">
									<template #body="slotProps">
										{{slotProps.data[i18n.locale()]}}
									</template>
								</Column>
								<Column :header="$t('Status')" headerStyle="width: 10%">
									<template #body="slotProps">
										<span class="p-tag p-tag-info"  v-if="slotProps.data.activo">{{$t('Asset')}}</span>
										<span class="p-tag p-tag-info"  v-else>{{$t('Inactive')}}</span>
									</template>
								</Column>
								<Column :header="$t('Actions')" headerStyle="width: 15%">
									<template #body="slotProps">
										<Button icon="pi pi-pencil" class="p-button-rounded p-button-secondary p-mr-2" @click="Edit2(slotProps.data)" v-if="edit"  v-tooltip.bottom="$t('Edit')"/>
										<Button icon="pi pi-trash" class="p-button-rounded p-button-secondary" @click="confirmDeleteProduct2(slotProps.data)" v-if="del"  v-tooltip.bottom="$t('Delete')"/>
									</template>
								</Column>
							</DataTable>
						</div>
					</template>
				</DataTable>
				<Dialog v-model:visible="productDialog" :style="{width: size}" :header="$t('Edit')" :modal="true" class="p-fluid" :maximizable="true">
					<br>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-col-12 p-md-12 p-lg-12">
								<label for="name">{{$t('English')}}</label>
								<InputText id="name" v-model="product.en" required="true"/>
							</div>
							<div class="p-col-12 p-md-12 p-lg-12">
								<label for="name">{{$t('Spanish')}}</label>
								<InputText id="name" v-model="product.es" required="true"/>
							</div>
							<div class="p-col-12 p-md-12 p-lg-2">
								<label for="icon">{{$t('Weight')}}</label>
								<InputNumber v-model="product.weight" suffix=" %" min="0" :max="(100-total)"/>
							</div>
							<div class="p-col-12 p-md-12 p-lg-2">
								<label for="icon">{{$t('Item')}}</label>
								<InputText id="name" v-model="product.ord" type="number" min="1"/>
							</div>
							<div class="p-col-6 p-md-6 p-lg-2">
								<h6 style="margin-top: 0">{{$t('Status')}}</h6>
								<InputSwitch v-model="product.activo" />
							</div>
						</div>
					</div>
					<template #footer>
						<Button :label="$t('Save')" icon="pi pi-check" class="p-button-secondary" @click="Save()" />
						<Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-secondary" @click="productDialog = false"/>
					</template>
				</Dialog>
				<Dialog v-model:visible="productDialog2" :style="{width: size}" :header="$t('Edit')" :modal="true" class="p-fluid" :maximizable="true">
					<br>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-col-12 p-md-12 p-lg-12">
								<strong for="name">{{$t('Control')}}: </strong>
								{{ product2.compliance[i18n.locale()] }}
							</div>
							<div class="p-col-12 p-md-12 p-lg-12">
								<label for="name">{{$t('English')}}</label>
								<Textarea v-model="product2.en" rows="3" autoResize/>
							</div>
							<div class="p-col-12 p-md-12 p-lg-12">
								<label for="name">{{$t('Spanish')}}</label>
								<Textarea v-model="product2.es" rows="3" autoResize/>
							</div>
							<div class="p-col-12 p-md-12 p-lg-2">
								<label for="icon">{{$t('Item')}}</label>
								<InputText id="name" v-model="product2.ord" type="number" min="1"/>
							</div>
							<div class="p-col-6 p-md-6 p-lg-2">
								<h6 style="margin-top: 0">{{$t('Status')}}</h6>
								<InputSwitch v-model="product2.activo" />
							</div>
						</div>
					</div>
					<template #footer>
						<Button :label="$t('Save')" icon="pi pi-check" class="p-button-secondary" @click="Save2()" />
						<Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-secondary" @click="productDialog2 = false"/>
					</template>
				</Dialog>
				<Dialog v-model:visible="deleteProductDialog" :style="{width: size}"  :header="$t('Confirm')" :modal="true">
					<br>
                    <div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="product">{{$t('Are you sure you want to delete?')}}</span>
					</div>
					<template #footer>
						<Button :label="$t('Yes')" icon="pi pi-check" class="p-button-secondary" @click="Delete" />
						<Button :label="$t('No')" icon="pi pi-times" class="p-button-secondary" @click="deleteProductDialog = false"/>
					</template>
				</Dialog>
				<Dialog v-model:visible="deleteProductDialog2" :style="{width: size}"  :header="$t('Confirm')" :modal="true">
					<br>
                    <div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="product">{{$t('Are you sure you want to delete?')}}</span>
					</div>
					<template #footer>
						<Button :label="$t('Yes')" icon="pi pi-check" class="p-button-secondary" @click="Delete2" />
						<Button :label="$t('No')" icon="pi pi-times" class="p-button-secondary" @click="deleteProductDialog2 = false"/>
					</template>
				</Dialog>

			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/API";
	var Consult = new API('Parameters','Compliance');
	import { useI18nPlugin } from '@unify/vuex-i18n';

	export default {
		data() {
			return {
				i18n: null,
				newid: false,
				newid2: false,
				search: null,
				options: [],
				option: null,
				options2: [
					{id: 1, icon: 'pi pi-plus-circle'}, 
					{id: -1, icon: 'pi pi-minus-circle'}
				],
				insert: false,
				edit: false,
				del: false,
				products: [],
				productDialog: false,
				productDialog2: false,
				deleteProductDialog: false,
				deleteProductDialog2: false,
				deleteProductsDialog: false,
				product: {},
				product2: {},
				total: 0,
				selectedCustomers: null,
				filters: {},
				size: '60vw',
				expandedRows: [],
			}
		},
		productService: null,
		created() {
			this.i18n = useI18nPlugin();
			this.$store.commit('Validate',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Start();
		},
		mounted() {
			
		},
		methods: {
			Start() {
				this.product = [];
				Consult.Ini().then(response => {
					//this.$store.state.error = response;
					this.insert = response.insert;
					this.edit = response.edit;
					this.del = response.delete;
					this.products = response.result.compliance;
					this.total = response.result.total;
				}); 
			},
			New() {
				this.newid = true;
				this.product = {
					en: null,
					es: null,
					activo: true,
                    order: 0,
					weight: 0
				};
				this.productDialog = true;
			},
			New2(data) {
				this.newid2 = true;
				this.product2 = {
					compliance: data,
					en: null,
					es: null,
					activo: true,
                    order: 0,
				};
				this.productDialog2 = true;
			},
			Edit(product) {
				this.newid = false;		
				this.product = {...product};
				this.productDialog = true;
			},
			Edit2(product) {
				this.newid2 = false;
				let compliance = this.products.filter(val => val.id == product.compliance)[0];
				this.product2 = {...product};
				this.product2.compliance = compliance;
				this.productDialog2 = true;
			},
			Save() {
				if ( this.product.en == null || this.product.en == '' || 
                    this.product.es == null || this.product.es == '' || 
                    this.product.ord == null || this.product.ord == 0) {
					this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Incomplete data'), life: 5000});
				} else {	
					let condition = 'UPD';
                    if (this.newid ) { condition = 'INS'; }
					Consult.Process(condition,{
						inf: this.product,
					}).then(response => {
						//this.$store.state.error = response;
						this.$toast.add(Consult.Mens(response.result,this.i18n.locale()));
						if (response.result) {
							this.productDialog = false;
							this.Start();
						} 
					});
				}
			},
			Save2() {
				if ( this.product2.en == null || this.product2.en == '' || 
                    this.product2.es == null || this.product2.es == '' || 
                    this.product2.ord == null || this.product2.ord == 0) {
					this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Incomplete data'), life: 5000});
				} else {	
					let condition = 'UPD2';
                    if (this.newid2 ) { condition = 'INS2'; }
					Consult.Process(condition,{
						inf: this.product2,
					}).then(response => {
						//this.$store.state.error = response;
						this.$toast.add(Consult.Mens(response.result,this.i18n.locale()));
						if (response.result) {
							this.productDialog2 = false;
							this.Start();
						} 
					});
				}
			},
			confirmDeleteProduct(product) {
				this.product = product;
				this.deleteProductDialog = true;
			},
			confirmDeleteProduct2(product) {
				this.product2 = product;
				this.deleteProductDialog2 = true;
			},
			Delete() {
				Consult.Process('DEL',{
					inf: 	this.product.id
				}).then(response => {
					//this.$store.state.error = response;
					this.$toast.add(Consult.Mens(response.result,this.i18n.locale()));
					if (response.result) {
						this.deleteProductDialog = false;
						this.Start();
					} 				
				});
			},
			Delete2() {
				Consult.Process('DEL2',{
					inf: 	this.product2.id
				}).then(response => {
					//this.$store.state.error = response;
					this.$toast.add(Consult.Mens(response.result,this.i18n.locale()));
					if (response.result) {
						this.deleteProductDialog2 = false;
						this.Start();
					} 				
				});
			},
		}
	}
</script>

<style scoped lang="scss">
@media (min-width: 1025px) {
    .pag-padding {
        padding: 0px 20vh 0rem 20vh;
    }
}
@media (max-width: 1024px) {
    .pag-padding {
        padding: 0px 0rem 0rem 0rem;
    }
}

.ttitle{
    font-family: 'Rajdhani', sans-serif;
    font-size: calc(1.2rem + 0.5vw);
    font-weight: 600;
    color: #2b3b80;
    padding: 1em 0em 1em 0.5em;
}
.text-title{
        font-family: 'Rajdhani', sans-serif;
        font-size: calc(0.7rem + 1vw);
        font-weight: 600;
        color: #2b3b80;
    }

	::v-deep(.p-progressbar) {
	height: .5rem;
	background-color: #D8DADC;

	.p-progressbar-value {
		background-color: #607D8B;
	}
}

.p-datatable .p-column-filter {
	display: none;
}

.table-header {
	display: flex;
	justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
	.p-datatable-header {
		padding: 1rem;
		text-align: left;
		font-size: 1.5rem;
	}

	.p-paginator {
		padding: 1rem;
	}

	.p-datatable-thead > tr > th {
		text-align: left;
	}

	.p-datatable-tbody > tr > td {
		cursor: auto;
	}

	.p-dropdown-label:not(.p-placeholder) {
		text-transform: uppercase;
	}
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

.customer-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-qualified {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-unqualified {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-negotiation {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.status-new {
		background: #B3E5FC;
		color: #23547B;
	}

	&.status-renewal {
		background: #ECCFFF;
		color: #694382;
	}

	&.status-proposal {
		background: #FFD8B2;
		color: #805B36;
	}
}

.p-progressbar-value.ui-widget-header {
	background: #607d8b;
}

@media (max-width: 640px) {
	.p-progressbar {
		margin-top: .5rem;
	}
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
	padding: 1rem;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.order-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.order-delivered {
		background: #C8E6C9;
		color: #256029;
	}

	&.order-cancelled {
		background: #FFCDD2;
		color: #C63737;
	}

	&.order-pending {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.order-returned {
		background: #ECCFFF;
		color: #694382;
	}
}

@media screen and (max-width: 1024px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 500% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}
}
</style>