<template>
    <div>
        <div v-if="this.$store.state.authenticated">
            <Menu :menu="$store.state.menu[i18n.locale()]" v-if="this.$store.state.authenticated"  style="padding: 0em 3em 0em 3em;"/>
        </div>
    </div>
</template>

<script>
import { useI18nPlugin } from '@unify/vuex-i18n';
import { defineAsyncComponent } from "@vue/runtime-core";

export default {
	components: {
        Menu: defineAsyncComponent(() => import('../components/Menu.vue')),
    },
	data() {
		return {
            i18n: null,
            dark: false,
			responsiveOptions: [
				{
                    breakpoint: '1024px',
                    numVisible: 5
                },
                {
                    breakpoint: '768px',
                    numVisible: 3
                },
                {
                    breakpoint: '560px',
                    numVisible: 1
                }
			]
			
		}
	},
	productService: null,
	eventService: null,
	created() {
        this.i18n = useI18nPlugin();
	},
	mounted() {
	},
	methods: {


    },
	computed: {
    }

}
</script>
<style>
</style>