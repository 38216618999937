<template>
    <div class="p-grid" style="padding: 90px 1em 1em 1em;">
		<div class="ttitle">
			{{$store.state.title[i18n.locale()]}}
			<Button icon="pi pi-refresh" class="p-button-rounded p-button-secondary" v-tooltip.bottom="$t('Refresh')" @click="Start" style=" position: absolute;right: 5px;"/>
		</div>
		<div class="p-grid p-fluid dashboard" style="padding: 1em 1em 1em 1em;" v-if="view">
			<div class="p-col-12 p-lg-3" v-for="(stats, index) in indicators" :key="index">
				<Card class="summary" style="height: 150px;">
					<template #title>
						<span class="title">{{$t(stats.name)}}</span>
						<span class="count visitors">{{stats.cant}}</span>
					</template>
					<template #content>
						<span class="detail" v-for="(descr, index) in stats.description" :key="index">
							{{$t(descr.name)}}: {{descr.cant}}
						</span>
					</template>
				</Card>
			</div>

			<div class="p-col-12 p-lg-6" >
				<Card class="summary">
					<template #title>
						<span class="title">{{$t('Roles')}}</span>
					</template>
					<template #content>
						<Chart type="doughnut" :data="roles"/>
					</template>
				</Card>
			</div>
			<div class="p-col-12 p-lg-6" >
				<Card class="summary">
					<template #title>
						<span class="title">{{$t('Test')}}</span>
					</template>
					<template #content>
						<Chart type="bar" :data="test"/>
					</template>
				</Card>
			</div>
        </div>
    </div>
</template>

<script>
	import API from "../service/API";
	var Consult = new API('Manager','Dashboard');
	import { useI18nPlugin } from '@unify/vuex-i18n';

	export default {
        components: {
        },
		data() {
			return {
				view: false,
				i18n: null,
				insert: false,
				edit: false,
				del: false,
				size: '60vw',
				graphs: [],
				indicators: [],
				colors: ['#F7B966','#F4AC86','#F7918A','#E985A7','#D957AC','#A983E8','#3953F6'],
				roles: {
					labels: [],
					datasets: [
						{
							data: [],
							backgroundColor: [],
							hoverBackgroundColor: []
						}
					]
				},
				test: {
					labels: [],
					datasets: [
						{
							label: null,
							data: [],
							fill: false,
							backgroundColor: null,
							borderColor: null
						},
					]
				}
				
			}
		},
		created() {
			this.i18n = useI18nPlugin();
			this.$store.commit('Validate',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Start();
		},
		mounted() {
			
		},
		methods: {
			Start() {
                this.view = false;
				this.false = true;
				this.indicators = [];
				Consult.Ini().then(response => {
					//this.$store.state.error = response.result.graphs.test;
					this.insert = response.insert;
					this.edit = response.edit;
					this.del = response.delete;
					this.indicators = response.result.indicators;
					this.graphs = response.result.graphs;
					this.Graph();
				}); 
			},
			Graph() {
				this.roles.labels = this.graphs.roles[this.i18n.locale()];
				this.roles.datasets[0].data = this.graphs.roles.data;
				this.roles.datasets[0].backgroundColor = this.graphs.roles.colors;
				this.roles.datasets[0].hoverBackgroundColor = this.graphs.roles.colors;
				this.test.labels = this.graphs.test.name;
				switch (this.i18n.locale()){
					case 'es':
						this.test.datasets[0].label = 'Registros';
						break;
					case 'ja':
						this.test.datasets[0].label = '記録';
						break;
					default:
						this.test.datasets[0].label = 'Records';
				}
				this.test.datasets[0].data = this.graphs.test.data;
				this.test.datasets[0].backgroundColor = this.graphs.test.colors;
				this.test.datasets[0].borderColor = this.graphs.test.colors;


				this.view = true;
			}
		}
	}
</script>

<style scoped lang="scss">
.code-fondo{
	background-repeat: no-repeat; 
	background-position: 50% 50%; 
	background-size: 100%;
}
.ttitle{
    font-family: 'Rajdhani', sans-serif;
    font-size: calc(0.7rem + 1vw);
    font-weight: 600;
    color: #2b3b80;
    padding: 1em 0em 1em 0.5em;
}

.text-title{
        font-family: 'Rajdhani', sans-serif;
        font-size: calc(0.7rem + 1vw);
        font-weight: 600;
        color: #2b3b80;
    }

	::v-deep(.p-progressbar) {
	height: .5rem;
	background-color: #D8DADC;

	.p-progressbar-value {
		background-color: #607D8B;
	}
}

.p-datatable .p-column-filter {
	display: none;
}

.table-header {
	display: flex;
	justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
	.p-datatable-header {
		padding: 1rem;
		text-align: left;
		font-size: 1.5rem;
	}

	.p-paginator {
		padding: 1rem;
	}

	.p-datatable-thead > tr > th {
		text-align: left;
	}

	.p-datatable-tbody > tr > td {
		cursor: auto;
	}

	.p-dropdown-label:not(.p-placeholder) {
		text-transform: uppercase;
	}
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

.customer-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-qualified {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-unqualified {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-negotiation {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.status-new {
		background: #B3E5FC;
		color: #23547B;
	}

	&.status-renewal {
		background: #ECCFFF;
		color: #694382;
	}

	&.status-proposal {
		background: #FFD8B2;
		color: #805B36;
	}
}

.p-progressbar-value.ui-widget-header {
	background: #607d8b;
}

@media (max-width: 640px) {
	.p-progressbar {
		margin-top: .5rem;
	}
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
	padding: 1rem;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.order-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.order-delivered {
		background: #C8E6C9;
		color: #256029;
	}

	&.order-cancelled {
		background: #FFCDD2;
		color: #C63737;
	}

	&.order-pending {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.order-returned {
		background: #ECCFFF;
		color: #694382;
	}
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}
}
</style>