<template>
	<div class="card" style="padding: 90px 1em 1em 1em;">
        <div class="p-grid">
                <div class="ttitle">{{ $t('My profile')}}</div>
                <div class="p-grid">
                    <div class="p-col-12 p-md-2"  style="padding: 1em 1em 1em 1em;">
                        <div class="p-grid">
                            <div class="p-col-12 p-lg-12">
                                <img :src="profile.photo" class="imgRedonda"  style="width:100%;"/>
                            </div>
                            <div class="p-col-12 p-lg-12">
                                <FileUpload :chooseLabel="$t('Change photo')" uploadLabel="Cargar" mode="basic" :name="img" :url="url+'/upload.php'"  accept="image/jpeg,image/jpg" :maxFileSize="4000000" @upload="Photo()" :auto="true"/>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-8">
                        <div class="card p-fluid">
                            <div class="p-field p-grid">
                                <label for="name3" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{$t('Email')}}</label>
                                <div class="p-col-12 p-md-10">
                                    <InputText v-model="profile.email" type="email" disabled/>
                                </div>
                            </div>
                            <div class="p-field p-grid">
                                <label for="name3" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{$t('Nickname')}}</label>
                                <div class="p-col-12 p-md-10">
                                    <InputText v-model="profile.nickname" type="text"/>
                                </div>
                            </div>
                            <div class="p-field p-grid">
                                <label for="email3" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{$t('Name')}}</label>
                                <div class="p-col-12 p-md-10">
                                    <InputText v-model="profile.name" type="text"/>
                                </div>
                            </div>
                            <div class="p-field p-grid">
                                <label for="email3" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">{{$t('Last name')}}</label>
                                <div class="p-col-12 p-md-10">
                                    <InputText v-model="profile.last" type="text"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-2">
                        <div class="p-grid" style="align-items: right;  justify-content: right;">
                            <div class="p-col-12 p-lg-12">
                                <Button :label="$t('Save')" icon="pi pi-save" class="p-button-secondary" @click="Save"/>
                            </div>
                            <div class="p-col-12 p-lg-12">
                                <Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-secondary" @click="Start"/>
                            </div>
                        </div>
                    </div>                    
                </div>
                <div class="p-grid">
                    <div class="p-col-12 p-lg-4">
						<label for="name">{{$t('Country')}}</label>
                        <Dropdown v-model="profile.nationality" :options="nationality" :optionLabel="i18n.locale()" :placeholder="$t('Select')+'...'"/>
					</div>
                    <div class="p-col-12 p-lg-4">
						<label for="name">{{$t('Academic Level')}}</label>
                        <Dropdown v-model="profile.academic" :options="academic" :optionLabel="i18n.locale()" :placeholder="$t('Select')+'...'"/>
					</div>
                    <div class="p-col-12 p-lg-4">
						<label for="name">{{$t('Industrial Sector')}}</label>
                        <Dropdown v-model="profile.industrysector" :options="industrysector" :optionLabel="i18n.locale()" :placeholder="$t('Select')+'...'"/>
					</div>
                    <div class="p-col-12 p-lg-4">
						<label for="name">{{$t('Job Category')}}</label>
                        <Dropdown v-model="profile.jobtype" :options="jobtype" :optionLabel="i18n.locale()" :placeholder="$t('Select')+'...'" @change="Jobtype1"/>
					</div>
                    <div class="p-col-12 p-lg-4">
						<label for="name">{{$t('Job Sub-Category')}}</label>
                        <Dropdown v-model="profile.jobtype_sub" :options="jobtype1" :optionLabel="i18n.locale()" :placeholder="$t('Select')+'...'" @change="Jobtype2"/>
					</div>
                    <div class="p-col-12 p-lg-4">
						<label for="name">{{$t('Job Type')}}</label>
                        <Dropdown v-model="profile.jobtype_component" :options="jobtype2" :optionLabel="i18n.locale()" :placeholder="$t('Select')+'...'"/>
					</div>
                </div>
                <br>
		</div>
	</div>
</template>

<script>
import API from "../service/API";
var Consult = new API('Security','Myprofile');
import { useI18nPlugin } from '@unify/vuex-i18n';
import Token from "uuid-token-generator";

export default {

    data() {
        return {
            profile: {},
            nationality: [],
            industrysector: [],
            jobtype: [],
            jobtype1: [],
            jobtype2: [],
            jobtype_sub: [],
            jobtype_component: [],
            academic: [],
            size: '50%',
			img: null,
            //url: 'http://localhost/vue-project/Zelfium/src/api',
            url: "./api",
		}
	},
    created() {      
        if (this.$store.state.mobile){this.size = '90%';}
        this.i18n = useI18nPlugin();
		const tokgen = new Token(256);
		this.img = tokgen.generate();
        this.Start();
    },
    methods: {
        Start() {
			this.profile = {};
			Consult.Ini().then(response => {
				//this.$store.state.error = response.result;
                this.profile = response.result.profile;
                this.$store.state.name = response.result.profile.nickname;  
                this.nationality = response.result.nationality;
                this.industrysector = response.result.industrysector;
                this.jobtype = response.result.jobtype;
                this.jobtype_sub = response.result.jobtype_sub;
                this.jobtype_component = response.result.jobtype_component;
                this.academic = response.result.academic;
                if(response.result.profile.nationality){
                    this.profile.nationality = this.nationality.filter(val => val.id == response.result.profile.nationality)[0];
                }
                if(response.result.profile.industrysector){
                    this.profile.industrysector = this.industrysector.filter(val => val.id == response.result.profile.industrysector)[0];                
                }
                if(response.result.profile.jobtype){
                    this.profile.jobtype = this.jobtype.filter(val => val.id == response.result.profile.jobtype)[0];
                    this.Jobtype1();
                    this.Jobtype2(); 
                }
                if(response.result.profile.academic){
                    this.profile.academic = this.academic.filter(val => val.id == response.result.profile.academic)[0];
                }
                this.profile.photo = this.$store.state.photo;
			}); 
		},
        Jobtype1(){
            this.jobtype1 = this.jobtype_sub.filter(val => val.jobtype == this.profile.jobtype.id);
            this.profile.jobtype_sub = this.jobtype1.filter(val => val.id == this.profile.jobtype_sub)[0];
        },
        Jobtype2(){
            this.jobtype2 = this.jobtype_component.filter(val => val.jobtype_sub == this.profile.jobtype_sub.id);
            this.profile.jobtype_component = this.jobtype2.filter(val => val.id == this.profile.jobtype_component)[0];
        },
        Save() {
            if (this.profile.nickname == null || this.profile.nickname == '') {
                this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Error in Mail'), life: 10000});
            } else {
                Consult.Process('UPD',{
                    profile: this.profile
                }).then(response => {
					//this.$store.state.error = response;
					this.$toast.add(Consult.Mens(response.result,this.i18n.locale()));
					if (response.result) {
                        if(this.profile.photo){this.$store.state.photo = this.profile.photo;}
						this.Start();
					} 
				});
            }   
        },
		Photo() {
			Consult.Process('photo',{
				new: this.img,
                old: this.$store.state.photo,
			}).then(response => {
                //this.$store.state.error = response;
				if(response.result){
					this.profile.photo = this.url+'/photo/'+response.result;
				} 
                const tokgen = new Token(256);
                this.img = tokgen.generate();
			});
		},
	},

}
</script>
<style scoped lang="scss">
.ttitle{
    font-family: 'Rajdhani', sans-serif;
    font-size: calc(0.7rem + 1vw);
    font-weight: 600;
    color: #2b3b80;
}

.imgRedonda {
    width:300px;
    border-radius:150px;
}
</style>