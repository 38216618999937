<template>
    <div class="card" style="padding: 90px 1em 1em 1em;">
        <div class="p-grid">
            <div class="ttitle">{{$store.state.title[i18n.locale()]}}</div>
            <div class="p-col-12">
				<DataTable :value="products" :paginator="true" stripedRows class="p-datatable-customers p-datatable-sm"
                        :rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters"
						:rowsPerPageOptions="[5, 10, 20, 50]" scrollable scrollHeight="400px">
					<template #header>
						<div class="table-header">
							<div>{{$t('Records')}}: {{ products.length }}</div>
							<div>
								<Button icon="pi pi-refresh" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" v-tooltip.bottom="$t('Refresh')" @click="Start"/>
							</div>
						</div>
					</template>
					<Column field="id" :header="$t('Date')" headerStyle="width: 10%">
						<template #body="slotProps">
							{{slotProps.data['date_'+i18n.locale()]}}
						</template>
					</Column>
					<Column field="responsible" :header="$t('Responsible')"/>
					<Column :header="$t('Internal Evaluation')" headerStyle="width: 10%">
						<template #body="slotProps">
							<Tag :style="'background-color: #'+Scale(slotProps.data.internal)['color']" v-if="slotProps.data.stage>0">
								{{parseFloat(slotProps.data.internal).toFixed(0)}}
								{{Scale(slotProps.data.internal)[i18n.locale()]}}
							</Tag>
						</template>
					</Column>
					<Column :header="$t('External Evaluation')" headerStyle="width: 10%">
						<template #body="slotProps">
							<Tag :style="'background-color: #'+Scale(slotProps.data.external)['color']" v-if="slotProps.data.stage_ext>0">
								{{parseFloat(slotProps.data.external).toFixed(0)}}
								{{Scale(slotProps.data.external)[i18n.locale()]}}
							</Tag>
						</template>
					</Column>
					<Column :header="$t('Internal Progress')" headerStyle="width: 10%">
						<template #body="slotProps">
                           <ProgressBar :value="(parseInt(slotProps.data.comp1)*100/parseInt(slotProps.data.ans1)).toFixed(1)" v-if="slotProps.data.ans1>0"/>
                           <ProgressBar value="0" v-else/>                            
						</template>
					</Column>
					<Column :header="$t('External Progress')" headerStyle="width: 10%">
						<template #body="slotProps">
                           <ProgressBar :value="(parseInt(slotProps.data.comp2)*100/parseInt(slotProps.data.ans2)).toFixed(1)" v-if="slotProps.data.ans2>0"/>
                           <ProgressBar value="0" v-else/>                            
						</template>
					</Column>
					<Column :header="$t('Stage')" headerStyle="width: 10%">
						<template #body="slotProps">
							<span class="p-tag p-tag-info" style="width: 100%; height: 30px">
								{{slotProps.data['stages_'+i18n.locale()]}}
							</span>
						</template>
					</Column>
					<Column :header="$t('Actions')" headerStyle="width: 15%">
						<template #body="slotProps">
							<Button icon="pi pi-eye" class="p-button-rounded  p-button-secondary p-mr-2" @click="Result(slotProps.data)" v-if="slotProps.data.stage>0"  v-tooltip.bottom="$t('Result')" />
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="ExtDialog" :style="{width: size}"  :header="$t('External Evaluation')" :modal="true">
					<br>
                    <div class="p-grid">
						<div class="p-col-12 p-md-12 p-lg-12">
							<strong>{{$t('Evaluator')}}: </strong>
							<InputText v-model="product.evaluator" style="width: 100%;" disabled/>	
						</div>
						<div class="p-col-12 p-md-12 p-lg-12">
							<strong>{{$t('Email')}}: </strong>
							<InputText v-model="product.ext_email" type="email" style="width: 100%;"/>	
						</div>
					</div>
					<template #footer>
						<Button :label="$t('Save')" icon="pi pi-check" class="p-button-secondary" @click="Save_Ext" />
						<Button :label="$t('Close')" icon="pi pi-times" class="p-button-secondary" @click="ExtDialog = false"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductDialog" :style="{width: size}"  :header="$t('Confirm')" :modal="true">
					<br>
                    <div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="product">{{$t('Are you sure you want to delete?')}}</span>
					</div>
					<template #footer>
						<Button :label="$t('Yes')" icon="pi pi-check" class="p-button-secondary" @click="Delete" />
						<Button :label="$t('No')" icon="pi pi-times" class="p-button-secondary" @click="deleteProductDialog = false"/>
					</template>
				</Dialog>
			</div>
		</div>
	</div>
	<Dialog v-model:visible="ResultDialog"   :header="$t('Result')" :modal="true" :maximizable="true">
        <Results :result="results" :scl="scale"/>
		<template #footer>
			<Button :label="$t('Close')" icon="pi pi-times" class="p-button-secondary" @click="ResultDialog = false"/>
		</template>
    </Dialog>
	<Eval @save-close="Start" :intern="true" v-if="$store.state.evaluation.display"/>
</template>

<script>
	import API from "../service/API";
	var Consult = new API('Evaluation','GAPAnalysis');
	import { useI18nPlugin } from '@unify/vuex-i18n';
    import { defineAsyncComponent } from "@vue/runtime-core";

	export default {
        components: {
			Eval: defineAsyncComponent(() => import('../components/Evaluation.vue')),
			Results: defineAsyncComponent(() => import('../components/Results.vue')),
        },
		data() {
			return {
				i18n: null,
				newid: false,
				search: null,
				options: null,
				option: null,
				insert: false,
				edit: false,
				del: false,
				products: [],
				productDialog: false,
				ResultDialog: false,
				ExtDialog: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				product: {},
				selectedCustomers: null,
				filters: {},
				size: '30vw',
				scale: [],
				results: null,
			}
		},
		productService: null,
		created() {
            this.i18n = useI18nPlugin();
			this.$store.commit('Validate',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Start();
		},
		mounted() {
			
		},
		methods: {
			Start() {
				this.product = [];
				Consult.Ini().then(response => {
					//this.$store.state.error = response;
					this.insert = response.insert;
					this.edit = response.edit;
					this.del = response.delete;
					this.scale = response.result.scale;
					this.products = response.result.evaluation;
				}); 
			},
			New() {
                Consult.Process('INS',{
					inf: null,
				}).then(response => {
					//this.$store.state.error = response;
					if (response.result) {
                        this.Start();
						this.Edit(response.result);
					} 
				});
			},
			Edit(product) {
				this.$store.state.evaluation.data = {...product};
                Consult.Process('ANS',{
					inf: 	product.id
				}).then(response => {
					//this.$store.state.error =  response;
					if (response.result) {
                        this.$store.state.evaluation.scale = response.result.scale;
                        this.$store.state.evaluation.action = response.result.action;
                        this.$store.state.evaluation.answers = response.result.answers;
						this.$store.state.evaluation.display = true;
					} 				
				});	
			},
			Edit_Ext(data){
				this.product = {...data};
				this.ExtDialog = true;
			},
			Save_Ext() {
				Consult.Process('External',{
					inf: 	this.product
				}).then(response => {
					//this.$store.state.error = response;
					this.$toast.add(Consult.Mens(response.result,this.i18n.locale()));
					if (response.result) {
						this.ExtDialog = false;
						this.Start();
					} 				
				});
			},
			Result(product) {
				this.results = null;
				this.$store.state.evaluation.data = {...product};
                Consult.Process('Result',{
					inf: 	product.id
				}).then(response => {
					//this.$store.state.error =  response;
					if (response.result) {
                        this.results = response.result;
						this.ResultDialog = true;
					} 				
				});	
			},
			confirmDeleteProduct(product) {
				this.product = product;
				this.deleteProductDialog = true;
			},
			Delete() {
				Consult.Process('DEL',{
					inf: 	this.product.id
				}).then(response => {
					//this.$store.state.error = response;
					this.$toast.add(Consult.Mens(response.result,this.i18n.locale()));
					if (response.result) {
						this.deleteProductDialog = false;
						this.Start();
					} 				
				});
			},
			Scale(valor){
				return this.scale.filter(val => parseFloat(val.min)<= parseFloat(valor) && parseFloat(val.max)>= parseFloat(valor))[0];
			},
		}
	}
</script>

<style scoped lang="scss">

.ttitle{
    font-family: 'Rajdhani', sans-serif;
    font-size: calc(0.7rem + 1vw);
    font-weight: 600;
    color: #2b3b80;
    padding: 1em 0em 1em 0.5em;
}
.text-title{
        font-family: 'Rajdhani', sans-serif;
        font-size: calc(0.7rem + 1vw);
        font-weight: 600;
        color: #2b3b80;
    }



.p-datatable .p-column-filter {
	display: none;
}

.table-header {
	display: flex;
	justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
	.p-datatable-header {
		padding: 1rem;
		text-align: left;
		font-size: 1.5rem;
	}

	.p-paginator {
		padding: 1rem;
	}

	.p-datatable-thead > tr > th {
		text-align: left;
	}

	.p-datatable-tbody > tr > td {
		cursor: auto;
	}

	.p-dropdown-label:not(.p-placeholder) {
		text-transform: uppercase;
	}
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

.customer-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-qualified {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-unqualified {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-negotiation {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.status-new {
		background: #B3E5FC;
		color: #23547B;
	}

	&.status-renewal {
		background: #ECCFFF;
		color: #694382;
	}

	&.status-proposal {
		background: #FFD8B2;
		color: #805B36;
	}
}



@media (max-width: 640px) {

}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
	padding: 1rem;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.order-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.order-delivered {
		background: #C8E6C9;
		color: #256029;
	}

	&.order-cancelled {
		background: #FFCDD2;
		color: #C63737;
	}

	&.order-pending {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.order-returned {
		background: #ECCFFF;
		color: #694382;
	}
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}


				}
			}
		}
	}
}
</style>