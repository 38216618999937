<template>
    <div class="card" style="padding: 90px 1em 1em 1em;">
        <div class="p-grid">
            <div class="ttitle">{{$store.state.title[i18n.locale()]}}</div>
            <div class="p-col-12">
				<DataTable :value="products" :paginator="true" stripedRows class="p-datatable-customers p-datatable-sm"
                        :rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters"
						:rowsPerPageOptions="[5, 10, 20, 50]" scrollable scrollHeight="400px">
					<template #header>
						<div class="table-header">
							<div>{{$t('Records')}}: {{ products.length }}</div>
							<span class="p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" :placeholder="$t('Search')+'...'" />
                            </span>
							<div>
								<Button icon="pi pi-plus" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" v-tooltip.bottom="$t('New')" @click="New" v-if="insert"/>
								<Button icon="pi pi-refresh" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" v-tooltip.bottom="$t('Refresh')" @click="Start"/>
							</div>
						</div>
					</template>
					<Column field="id" :header="$t('ID')" :sortable="true" headerStyle="width: 5%">
						<template #body="slotProps">
							{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="email" :header="$t('Email')" :sortable="true" headerStyle="width: 30%">
						<template #body="slotProps">
							{{slotProps.data.email}}
						</template>
					</Column>
					<Column field="name" :header="$t('Name')" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.name}}
						</template>
					</Column>
					<Column field="last" :header="$t('Last')" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.last}}
						</template>
					</Column>
					<Column :header="$t('Status')" headerStyle="width: 10%">
						<template #body="slotProps">
							<span class="p-tag p-tag-info"  v-if="slotProps.data.activo">{{$t('Asset')}}</span>
							<span class="p-tag p-tag-info"  v-else>{{$t('Inactive')}}</span>
						</template>
					</Column>
					<Column :header="$t('Actions')" headerStyle="width: 10%">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-secondary p-mr-2" @click="Edit(slotProps.data)" v-if="edit"  v-tooltip.bottom="$t('Edit')"/>
							<Button icon="pi pi-trash" class="p-button-rounded p-button-secondary" @click="confirmDeleteProduct(slotProps.data)" v-if="del"  v-tooltip.bottom="$t('Delete')"/>
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="productDialog" :style="{width: size}" :header="$t('Edit')" :modal="true" class="p-fluid" :maximizable="true">
					<br>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-col-6 p-md-6 p-lg-2">
								<label for="name">ID</label>
								<InputText v-model="product.id" placeholder="Disabled" :disabled="true"></InputText>						
							</div>
							<div class="p-col-12 p-md-12 p-lg-5">
								<label for="name">{{$t('Name')}}</label>
								<InputText id="name" v-model.trim="product.name" required="true"/>
							</div>
							<div class="p-col-12 p-md-12 p-lg-5">
								<label for="name">{{$t('Last')}}</label>
								<InputText id="name" v-model="product.last" required="true"/>
							</div>
							<div class="p-col-12 p-md-12 p-lg-5">
								<label for="name">{{$t('Email')}}</label>
								<InputText id="name" v-model="product.email" required="true"/>
							</div>
							<div class="p-col-12 p-md-12 p-lg-5">
								<label for="name">{{$t('Group')}}</label>
                                <Dropdown v-model="product.groups" :options="group" optionLabel="name" :placeholder="$t('Select')+'...'"/>
							</div>
							<div class="p-col-6 p-md-6 p-lg-2">
								<h6 style="margin-top: 0">{{$t('Status')}}</h6>
								<InputSwitch v-model="product.activo" />
							</div>
							<div class="p-field" v-if="rol.length>0">
								<h5>{{$t('Permissions')}}</h5>
								<div class="p-grid">
									<div class="p-col-6"  v-for="(stats, index) in rol" :key="index">
										<div class="p-grid">
											<div class="p-col-8">
												{{$t(stats[i18n.locale()])}}
											</div>
											<div class="p-col-4">
												<InputSwitch :id="index" v-model="rol[index].activo" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<template #footer>
						<Button :label="$t('Save')" icon="pi pi-check" class="p-button-secondary" @click="Save()" />
						<Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-secondary" @click="productDialog = false"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductDialog" :style="{width: size}"  :header="$t('Confirm')" :modal="true">
					<br>
                    <div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="product">{{$t('Are you sure you want to delete?')}}</span>
					</div>
					<template #footer>
						<Button :label="$t('Yes')" icon="pi pi-check" class="p-button-secondary" @click="Delete" />
						<Button :label="$t('No')" icon="pi pi-times" class="p-button-secondary" @click="deleteProductDialog = false"/>
					</template>
				</Dialog>

			</div>
		</div>
	</div>
</template>

<script>
	import API from "../service/API";
	var Consult = new API('Security','Users');
	import { useI18nPlugin } from '@unify/vuex-i18n';

	export default {
		data() {
			return {
				i18n: null,
				newid: false,
				search: null,
				options: null,
				option: null,
				insert: false,
				edit: false,
				del: false,
				products: [],
				productDialog: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				product: {},
				selectedCustomers: null,
				filters: {},
				size: '60vw',
				roles: [],
				rol: [],
				group: [],
			}
		},
		productService: null,
		created() {
			this.i18n = useI18nPlugin();
			this.$store.commit('Validate',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Start();
		},
		mounted() {
			
		},
		methods: {
			Start() {
				this.product = [];
				Consult.Ini().then(response => {
					//this.$store.state.error = response.result.roles;
					this.insert = response.insert;
					this.edit = response.edit;
					this.del = response.delete;
					this.products = response.result.users;
					this.roles = response.result.roles;
					this.group = response.result.group;
				}); 
			},
			New() {
				this.newid = true;
				this.product = {
					name: null,
					last: null,
					email: null,
					activo: true,
					group: null,
				};
				this.rol = this.roles;
				this.productDialog = true;
			},
			Edit(product) {
				this.newid = false;
				this.product = {...product};
				if(this.product.groups){
					this.product.groups = this.group.filter(val => val.id == this.product.groups)[0];
				}
				this.rol = [];
				Consult.Process('Permissions',{
					inf: product.id,
				}).then(response => {
					//this.$store.state.error = response;
					this.rol = response.result.rol;
					this.productDialog = true;
				});
			},		
			Save() {
				if (this.product.name == null || this.product.name == '') {
					this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Enter name'), life: 5000});
				} else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.
					test(this.product.email)) {
					this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Email error'), life: 10000});
				} else if (this.product.last == null || this.product.last == '') {
					this.$toast.add({severity:'error', summary: 'Message', detail: this.$t('Enter last'), life: 5000});
				} else if (this.product.email == null || this.product.email == '') {
					this.$toast.add({severity:'error', summary: 'Message', detail: this.$t('Enter email'), life: 5000});
				} else {	
					this.product.roles = this.rol;
					let condition = 'UPD';
					if (this.newid) { condition = 'INS'; }
					Consult.Process(condition,{
						inf: this.product,
					}).then(response => {
						//this.$store.state.error = response;
						this.$toast.add(Consult.Mens(response.result,this.i18n.locale()));
						if (response.result) {
							this.productDialog = false;
							this.Start();
						} 
					});
				}
			},
			confirmDeleteProduct(product) {
				this.product = product;
				this.deleteProductDialog = true;
			},
			Delete() {
				Consult.Process('DEL',{
					inf: 	this.product.id
				}).then(response => {
					//this.$store.state.error = response;
					this.$toast.add(Consult.Mens(response.result,this.i18n.locale()));
					if (response.result) {
						this.deleteProductDialog = false;
						this.Start();
					} 				
				});
			},
		}
	}
</script>

<style scoped lang="scss">
.ttitle{
    font-family: 'Rajdhani', sans-serif;
    font-size: calc(0.7rem + 1vw);
    font-weight: 600;
    color: #2b3b80;
    padding: 1em 0em 1em 0.5em;
}
.text-title{
        font-family: 'Rajdhani', sans-serif;
        font-size: calc(0.7rem + 1vw);
        font-weight: 600;
        color: #2b3b80;
    }

	::v-deep(.p-progressbar) {
	height: .5rem;
	background-color: #D8DADC;

	.p-progressbar-value {
		background-color: #607D8B;
	}
}

.p-datatable .p-column-filter {
	display: none;
}

.table-header {
	display: flex;
	justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
	.p-datatable-header {
		padding: 1rem;
		text-align: left;
		font-size: 1.5rem;
	}

	.p-paginator {
		padding: 1rem;
	}

	.p-datatable-thead > tr > th {
		text-align: left;
	}

	.p-datatable-tbody > tr > td {
		cursor: auto;
	}

	.p-dropdown-label:not(.p-placeholder) {
		text-transform: uppercase;
	}
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

.customer-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-qualified {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-unqualified {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-negotiation {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.status-new {
		background: #B3E5FC;
		color: #23547B;
	}

	&.status-renewal {
		background: #ECCFFF;
		color: #694382;
	}

	&.status-proposal {
		background: #FFD8B2;
		color: #805B36;
	}
}

.p-progressbar-value.ui-widget-header {
	background: #607d8b;
}

@media (max-width: 640px) {
	.p-progressbar {
		margin-top: .5rem;
	}
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
	padding: 1rem;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.order-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.order-delivered {
		background: #C8E6C9;
		color: #256029;
	}

	&.order-cancelled {
		background: #FFCDD2;
		color: #C63737;
	}

	&.order-pending {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.order-returned {
		background: #ECCFFF;
		color: #694382;
	}
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}
}
</style>