<template>
	<div class="p-grid" style="padding: 90px 1em 1em 1em;">
		<div class="p-col-12">
			<div class="card pag-padding">
				<div class="ttitle">
					{{$store.state.title[i18n.locale()]}}
					<Button icon="pi pi-refresh" class="p-button-rounded p-button-secondary p-mr-2" v-tooltip.bottom="$t('Refresh')" @click="Start"/>
				</div>
				<TabView v-if="view">
					<TabPanel :header="$t('Scoring Scale for Controls')">
						<Scale :c_insert="insert" :c_edit="edit" :c_del="del" :dats="scale"/>
					</TabPanel>
					<TabPanel :header="$t('Impact and Urengence')">
						<Impact__Urengence :c_insert="insert" :c_edit="edit" :c_del="del" :dats="impact__ureng"/>
					</TabPanel>
				</TabView>
			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/API";
	var Consult = new API('Parameters','Scoring');
	import { useI18nPlugin } from '@unify/vuex-i18n';
	import { defineAsyncComponent } from "@vue/runtime-core";

	export default {
		components: {
			Scale: defineAsyncComponent(() => import('../components/Scale.vue')),
			Impact__Urengence: defineAsyncComponent(() => import('../components/Impact__Urengence.vue')),
		},
		data() {
			return {
				i18n: null,
				insert: false,
				edit: false,
				del: false,
				view: false,
				scale: [],
				impact__ureng: []
			}
		},
		productService: null,
		created() {
			this.i18n = useI18nPlugin();
			this.$store.commit('Validate',this.$route);
			this.Start();
		},
		mounted() {
			
		},
		methods: {
			Start() {
				this.view = false;
				this.product = [];
				Consult.Ini().then(response => {
					//this.$store.state.error = response;
					this.insert = response.insert;
					this.edit = response.edit;
					this.del = response.delete;
					this.scale = response.result.scale;
					this.impact__ureng = response.result.impact__ureng;
					this.view = true;
				}); 
			},

		}
	}
</script>

<style scoped lang="scss">
@media (min-width: 1025px) {
    .pag-padding {
        padding: 0px 20vh 0rem 20vh;
    }
}
@media (max-width: 1024px) {
    .pag-padding {
        padding: 0px 0rem 0rem 0rem;
    }
}

.ttitle{
    font-family: 'Rajdhani', sans-serif;
    font-size: calc(1.2rem + 0.5vw);
    font-weight: 600;
    color: #2b3b80;
    padding: 1em 0em 1em 0.5em;
}
.text-title{
        font-family: 'Rajdhani', sans-serif;
        font-size: calc(0.7rem + 1vw);
        font-weight: 600;
        color: #2b3b80;
    }

	::v-deep(.p-progressbar) {
	height: .5rem;
	background-color: #D8DADC;

	.p-progressbar-value {
		background-color: #607D8B;
	}
}

.p-datatable .p-column-filter {
	display: none;
}

.table-header {
	display: flex;
	justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
	.p-datatable-header {
		padding: 1rem;
		text-align: left;
		font-size: 1.5rem;
	}

	.p-paginator {
		padding: 1rem;
	}

	.p-datatable-thead > tr > th {
		text-align: left;
	}

	.p-datatable-tbody > tr > td {
		cursor: auto;
	}

	.p-dropdown-label:not(.p-placeholder) {
		text-transform: uppercase;
	}
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

.customer-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-qualified {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-unqualified {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-negotiation {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.status-new {
		background: #B3E5FC;
		color: #23547B;
	}

	&.status-renewal {
		background: #ECCFFF;
		color: #694382;
	}

	&.status-proposal {
		background: #FFD8B2;
		color: #805B36;
	}
}

.p-progressbar-value.ui-widget-header {
	background: #607d8b;
}

@media (max-width: 640px) {
	.p-progressbar {
		margin-top: .5rem;
	}
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
	padding: 1rem;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.order-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.order-delivered {
		background: #C8E6C9;
		color: #256029;
	}

	&.order-cancelled {
		background: #FFCDD2;
		color: #C63737;
	}

	&.order-pending {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.order-returned {
		background: #ECCFFF;
		color: #694382;
	}
}

@media screen and (max-width: 1024px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 500% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}
}
</style>